import PageStructureQuery from '~/graphql/queries/page/structure.graphql';
import { useQuery } from '~/composables/villus';

export async function usePageQuery() {
    const route = useRoute();
    const uri = useParseRoute(route);

    const variables = {
        uri
    };

    return await useQuery(
        PageStructureQuery,
        variables
    );
}
