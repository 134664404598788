<template>
    <TemplateContentPage
        v-if="entry && entry.pageType === PAGE_TYPE_PROGRAM"
        :entry="entry"
    />

    <TemplateInfoPage
        v-else-if="entry"
        :entry="entry"
    />
</template>

<script setup>
import {
    PAGE_TYPE_PROGRAM
} from '~/constants/page-types';
import { usePageQuery } from '~/composables/villus/page/query.ts';
import { useCraftEntrySetup } from '~/composables/useCraftEntrySetup';
import useColorTheme from '~/composables/useColorTheme';

const { data } = await usePageQuery();
const entry = useCraftEntrySetup(data);

useColorTheme();
</script>
